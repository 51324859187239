<template>
  <ion-page class="page scrollable create-story-world-page">
    <div class="mb-4">
      <div class="text-center w-100 title">{{ title }}</div>
      <Cover class="mt-4" />
      <KeyInfo class="one-section" />
      <Sections />
      <AddSection class="one-section mt-3" />
      <Settings class="mt-5" />
      <Actions class="mt-4" />
    </div>
  </ion-page>
</template>

<script lang="ts" setup>
import Cover from './components/Cover.vue';
import KeyInfo from './components/KeyInfo.vue';
import Sections from './components/Sections.vue';
import AddSection from './components/AddSection.vue';
import Actions from './components/Actions.vue';
import Settings from './components/Settings.vue';
import { storyWorldCreatorStore } from '@/shared/pinia-store/storyworld-creator';
const { loadEditor, clearAll, isDirty, isDone, markIsDone } = storyWorldCreatorStore();

const route = useRoute();

const id = computed(() => {
  const { id } = route.params;
  if (Array.isArray(id)) return '';
  return id;
});

const title = computed(() => {
  return id.value ? 'Edit your Story World' : 'Weave your World';
});

onMounted(async () => {
  try {
    const routeName = route.name as string;
    await loadEditor(id.value, routeName);
  } catch (e) {
    markIsDone();
  }
});

onBeforeRouteLeave((to, from, next) => {
  if (isDone.value || !isDirty.value) return next();
  if (!confirm('Are you sure you want to leave? You may lose unsaved changes.')) {
    return;
  }
  next();
  clearAll();
});
</script>

<style lang="sass" scoped>
.create-story-world-page
  padding: 24px 12px !important
  // margin-top: -40px !important
  ::v-deep
    .reorder-selected
      border-radius: 10px !important
  .title
    color: #4D4D4D
    font-size: 40px
    font-weight: bold
  .one-section
    margin-top: 45px

@media(max-width: 500px)
  .title
    font-size: 30px !important
</style>
